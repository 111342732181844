@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$primary: #1b9827;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

html {
  height: 100%;
  overflow: auto;
}

body {
  background-color: #f0f0f0;
  height: 100%;
  overflow: auto;
}

#__next {
  height: 100%;
  overflow: auto;
}

.sidebar-list {
  .item.selected {
    background-color: rgba(63, 181, 68, 0.08);
  }
}

.reset-lineheight {
  line-height: 1;
}

a {
  color: $primary;
}

.draggable-list {
  border: 1px solid #ccc;
  .item {
    padding: 1;
    display: grid;
    cursor: pointer;

    grid-template-columns: 30px calc(100% - 60px) 30px;

    &-single {
      grid-template-columns: 1fr;
    }
    p {
      cursor: pointer;
    }
    &.disabled {
      opacity: 0.3 !important;
    }
    &.deleted {
      background-color: rgba(177, 20, 25, 0.2) !important;
    }
  }
}
.nested-options {
  display: grid;
  grid-template-columns: 1fr 35px 35px;
}

.file-details {
  margin-bottom: 8px;
  .MuiTypography-body2 {
    color: rgba(0, 0, 0, 0.6);
  }
}

.hidden {
  display: none;
}

.tab-list {
  .selected {
    color: $primary;
    border-bottom: 2px solid $primary;
  }
}

.ProseMirror {
  height: 100%;
  overflow: auto;
  padding: 4px 0px;
  box-sizing: border-box;
  line-height: 1.4375em;
}

.ProseMirror ul,
.ProseMirror ol {
  margin-left: 15px;
}

.ProseMirror blockquote {
  border-left: 1px solid #ccc;
  padding: 5px 15px;
}

.text-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.merge-field {
  padding: 5px 12px;
  background-color: #eeeeee;
  z-index: -1;
  border-radius: 15px;
}

.ProseMirror table {
  width: 100%;
  border-collapse: collapse;
}

.ProseMirror td,
.ProseMirror th {
  border: 1px solid black;
}

.ProseMirror-focused {
  border: none;
}

[contenteditable],
[contenteditable]:focus {
  outline: 0px solid transparent;
}
